import { type SwitchProps } from '@mui/material';
import { type Theme } from '@mui/material/styles';

interface SwitchSizeProps {
  base: number;
  thumb: number;
  width: number;
  height: number;
  trackRadius: number;
}

function getSizeStyle(size?: SwitchProps['size']): SwitchSizeProps {
  switch (size) {
    case 'ios':
      return { base: 22, thumb: 22, width: 42, height: 26, trackRadius: 26 };
    case 'small':
      return { base: 12, thumb: 10, width: 28, height: 16, trackRadius: 8 };
    case 'large':
      return { base: 32, thumb: 22, width: 60, height: 28, trackRadius: 24 };
    case 'medium':
    default:
      return { base: 22, thumb: 16, width: 44, height: 22, trackRadius: 16 };
  }
}

function switchStyle(theme: Theme, size?: SwitchProps['size']) {
  const sizes: SwitchSizeProps = getSizeStyle(size);

  return {
    width: sizes.width,
    height: sizes.height,
    '& .MuiSwitch-track': {
      borderRadius: sizes.trackRadius,
    },
    '& .MuiSwitch-thumb': {
      width: sizes.thumb,
      height: sizes.thumb,
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked': {
        transform: `translateX(${sizes.base}px)`,
      },
    },
  };
}

export default function Switch(theme: Theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        sizeLarge: { ...switchStyle(theme, 'large') },
        sizeSmall: {
          ...switchStyle(theme, 'small'),
        },
        track: {
          opacity: 1,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.secondary[400],
        },
        thumb: {
          borderRadius: '50%',
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        root: {
          margin: 8,
          padding: 0,
          display: 'flex',
          color: theme.palette.text.primary,
          '& ~ .MuiFormControlLabel-label': {
            margin: 6,
          },
          ...switchStyle(theme, 'medium'),
        },
        switchBase: {
          '&.Mui-disabled': {
            color: theme.palette.background.paper,
            '+.MuiSwitch-track': {
              opacity: 0.3,
            },
          },
          '&.Mui-checked': {
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
            },
            '&.Mui-disabled': {
              color: theme.palette.background.paper,
            },
          },
        },
        sizeIos: {
          ...switchStyle(theme, 'ios'),
          '& .MuiSwitch-thumb': {
            width: 22,
            height: 22,
            boxSizing: 'border-box',
          },
          '& .MuiSwitch-track': {
            opacity: 1,
            borderRadius: 26 / 2,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
          '& .MuiSwitch-switchBase': {
            margin: 2,
            padding: 0,
            transitionDuration: '300ms',

            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
            },
            '&.Mui-checked': {
              color: '#fff',
              transform: 'translateX(16px)',
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
              '& + .MuiSwitch-track': {
                border: 0,
                opacity: 1,
              },
            },
          },
        },
      },
    },
  };
}
