import { type JwtPayload } from '@features/auth/types';

function base64UrlDecode(base64Url: string): string {
  let base64 = base64Url.replace('-', '+').replace('_', '/');
  while (base64.length % 4) {
    base64 += '=';
  }

  const decodedBase64 = atob(base64);

  const textDecoder = new TextDecoder('utf-8');

  return textDecoder.decode(
    new Uint8Array([...decodedBase64].map((c) => c.charCodeAt(0)))
  );
}

export function decodeJwt(jwtToken: string): null | JwtPayload {
  const parts = jwtToken && String(jwtToken).split('.');
  if (parts?.length !== 3) {
    return null;
  }

  const payload = parts[1];
  try {
    return JSON.parse(base64UrlDecode(payload));
  } catch (error) {
    return null;
  }
}
