import { createApi } from '@reduxjs/toolkit/query/react';

import { API, ENV } from '@constants';
import { type Option } from '@shared/form/types';
import replaceURLParams from '@utils/replaceURLParams';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import { type IBonus } from '@modules/bonusManagement/types';
import { responseToSelectOptions } from '@utils/responseFormatters';
import {
  type IPlayer,
  type IGiveBonusPayload,
  type IValidatePlayerPayload,
  type IPlayerValidationStatus,
  type IGetBonusTemplatesPayload,
} from '@modules/giveBonus/types';

export const bonusServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'bonusService',
  baseQuery: (...args) =>
    baseQueryCreator(ENV.VITE_BONUS_SERVICE_API_URL)(...args),
});

export const giveBonusManagementApi = bonusServiceSlice.injectEndpoints({
  endpoints: (builder) => ({
    giveBonus: builder.mutation<IBonus, IGiveBonusPayload>({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: replaceURLParams(API.GIVE_BONUS_MANAGEMENT.ROOT, {
          playerId: payload.playerId,
        }),
      }),
    }),
    validatePlayer: builder.query<
      IPlayerValidationStatus,
      IValidatePlayerPayload
    >({
      query: ({ brandId, playerId }) => ({
        url: API.GIVE_BONUS_MANAGEMENT.VALIDATE_PLAYER,
        params: {
          brand_id: brandId,
          player_id: playerId,
        },
      }),
    }),
    getBonusTemplates: builder.query<Option[], IGetBonusTemplatesPayload>({
      transformResponse: (response: IPlayer[]) =>
        responseToSelectOptions<IPlayer[]>(response, { key: 'name' }),
      query: ({ type, brandId }) => ({
        url: API.GIVE_BONUS_MANAGEMENT.BONUS_TEMPLATES,
        params: {
          bonus_type: type,
          brand_id: brandId,
          calculation_type: 'FIXED',
        },
      }),
    }),
  }),
});

export const {
  useGiveBonusMutation,
  useLazyValidatePlayerQuery,
  useLazyGetBonusTemplatesQuery,
} = giveBonusManagementApi;
