import { createApi } from '@reduxjs/toolkit/query/react';

import { API, ENV } from '@constants';
import replaceURLParams from '@utils/replaceURLParams';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import { type ISearchParams } from '@features/filters/types';
import {
  type IPlayerOfferDetails,
  type IPlayerOffersSearch,
} from '@modules/playerOffers/types';

export const playerOffersServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'playerOffersService',
  baseQuery: (...args) =>
    baseQueryCreator(ENV.VITE_PLAYER_REPORTING_SERVICE_API_URL)(...args),
});

export const playerOffersApi = playerOffersServiceSlice
  .enhanceEndpoints({
    addTagTypes: ['player-offers-list'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlayerOffersList: builder.query<IPlayerOffersSearch, ISearchParams>({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.PLAYER_OFFERS.GRID,
        }),
      }),
      getPlayerOfferDetails: builder.query<IPlayerOfferDetails, string>({
        query: (id) => ({
          method: 'GET',
          url: replaceURLParams(API.PLAYER_OFFERS.OFFER.DETAILS, {
            id,
          }),
        }),
      }),
      exportPlayerOffersData: builder.mutation<
        {
          response: string;
          headers: Record<string, string>;
        },
        ISearchParams
      >({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.PLAYER_OFFERS.EXPORT,
          responseHandler: async (response: Response) => {
            const text = await response.text();
            const headers: Record<string, string> = {};
            response.headers.forEach((value: string, key: string) => {
              headers[key] = value;
            });
            return { headers, response: text };
          },
        }),
      }),
    }),
  });

export const {
  useLazyGetPlayerOffersListQuery,
  useLazyGetPlayerOfferDetailsQuery,
  useExportPlayerOffersDataMutation,
} = playerOffersApi;
