import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import { ROUTES } from '@constants';
import { NotFound } from '@pages/404';
import ModuleRoutes from '@routes/ModuleRoutes';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  ModuleRoutes,
  {
    path: ROUTES.ALL,
    element: <NotFound />,
  },
]);
