import { useState, useEffect } from 'react';

export const useIframeMode = () => {
  const [isIframe, setIsIframe] = useState(true);

  useEffect(() => {
    if (window.self === window.top) {
      setIsIframe(false);
    }
  }, []);

  return { isIframe };
};
