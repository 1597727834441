import { type Theme } from '@mui/material/styles';

export default function Accordion(theme: Theme) {
  return {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          maxHeight: '2.5rem',
          minHeight: '2.5rem',
          flexDirection: 'row-reverse',
          background: theme.palette.grey[200],
          '&.Mui-expanded': {
            minHeight: '2.5rem',
            maxHeight: '2.5rem',
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        },
      },
    },
  };
}
