import type { Theme } from '@mui/material/styles';

import merge from 'lodash/merge';

import Chip from '@themes/overrides/Chip';
import Link from '@themes/overrides/Link';
import Tabs from '@themes/overrides/Tabs';
import Radio from '@themes/overrides/Radio';
import Button from '@themes/overrides/Button';
import Switch from '@themes/overrides/Switch';
import Dialog from '@themes/overrides/Dialog';
import Tooltip from '@themes/overrides/Tooltip';
import Checkbox from '@themes/overrides/Checkbox';
import TableRow from '@themes/overrides/TableRow';
import DataGrid from '@themes/overrides/DataGrid';
import TableCell from '@themes/overrides/TableCell';
import InputBase from '@themes/overrides/InputBase';
import Accordion from '@themes/overrides/Accordion';
import TableHead from '@themes/overrides/TableHead';
import TableBody from '@themes/overrides/TableBody';
import ButtonBase from '@themes/overrides/ButtonBase';
import Pagination from '@themes/overrides/Pagination';
import IconButton from '@themes/overrides/IconButton';
import Typography from '@themes/overrides/Typography';
import CardContent from '@themes/overrides/CardContent';
import TableFooter from '@themes/overrides/TableFooter';
import ListItemIcon from '@themes/overrides/ListItemIcon';
import ToggleButton from '@themes/overrides/ToggleButton';
import Autocomplete from '@themes/overrides/Autocomplete';
import DateTimePicker from '@themes/overrides/DateTimePicker';
import PaginationItem from '@themes/overrides/PaginationItem';
import ListItemButton from '@themes/overrides/ListItemButton';
import TablePagination from '@themes/overrides/TablePagination';

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Button(theme),
    ButtonBase(),
    CardContent(),
    Checkbox(theme),
    Chip(theme),
    IconButton(theme),
    InputBase(theme),
    DateTimePicker(theme),
    Link(),
    ListItemButton(theme),
    ListItemIcon(theme),
    Pagination(),
    PaginationItem(theme),
    Radio(theme),
    Switch(theme),
    TableBody(theme),
    TableCell(theme),
    TableFooter(theme),
    TableHead(theme),
    TablePagination(),
    TableRow(),
    ToggleButton(theme),
    Tooltip(theme),
    Tabs(theme),
    Typography(),
    Autocomplete(),
    Accordion(theme),
    DataGrid(theme),
    Dialog(theme)
  );
}
