import * as Sentry from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';

import { ENV } from '@constants';
import { apiSlice } from '@api/apiSlice';
import rootReducer from '@store/rootReducer';
import { ruleEngineApiSlice } from '@modules/ruleEngine/service/ruleEngineApi';
import { playerOffersApi } from '@modules/playerOffers/service/playerOffersApi';
import { bonusServiceSlice } from '@modules/giveBonus/service/giveBonusManagementApi';
import { sportsDataServiceSlice } from '@modules/common/sportsData/service/sportsDataApi';
import { casinoGamesServiceSlice } from '@modules/common/casinoGames/service/casinoGamesApi';
import { playerReportingServiceSlice } from '@modules/playerBonuses/service/playerBonusesApi';
import { campaignMonitorReportingServiceSlice } from '@modules/campaignMonitorReport/service/campaignMonitorApi';
import { campaignActivityReportingServiceSlice } from '@modules/campaignActivityReport/service/campaignActivityApi';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer(state: RootState): unknown {
    return {
      ...state,
      api: null,
      menu: null,
      sportsData: null,
      casinoGames: null,
      bonusService: null,
      ruleEngineApi: null,
      playerReportingService: null,
      campaignMonitorService: null,
      campaignActivityService: null,
    };
  },
});

const middlewareList = [
  apiSlice.middleware,
  sportsDataServiceSlice.middleware,
  casinoGamesServiceSlice.middleware,
  bonusServiceSlice.middleware,
  ruleEngineApiSlice.middleware,
  playerReportingServiceSlice.middleware,
  playerOffersApi.middleware,
  campaignActivityReportingServiceSlice.middleware,
  campaignMonitorReportingServiceSlice.middleware,
];

export const store = configureStore({
  reducer: rootReducer,
  devTools: ENV.MODE === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewareList),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

export type { RootState, AppDispatch };
