import { NavLink } from 'react-router-dom';
import { Box, Stack, Button, useTheme, Typography } from '@mui/material';

import { ROUTES } from '@constants';

export const NotFound = () => {
  const theme = useTheme();

  return (
    <Stack alignItems="center">
      <Typography
        variant="h1"
        sx={{
          fontWeight: 700,
          fontSize: '6rem',
          marginTop: '5rem',
        }}
      >
        404
      </Typography>

      <Typography sx={{ fontWeight: 600, marginTop: '1rem' }}>
        This page does not exist
      </Typography>

      <Typography color={theme.palette.secondary.main}>
        The page you are looking for could not be found.
      </Typography>
      <Box marginTop="2rem">
        <Button
          size="large"
          variant="contained"
          component={NavLink}
          to={ROUTES.BONUS_MANAGEMENT.ROOT}
        >
          Go Home
        </Button>
      </Box>
    </Stack>
  );
};
