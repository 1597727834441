import { type SvgIconTypeMap } from '@mui/material';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type ComponentClass, type FunctionComponent } from 'react';
import { type OverridableComponent } from '@mui/material/OverridableComponent';

export type OverrideIcon =
  | ComponentClass<any>
  | FunctionComponent<any>
  | ({
      muiName: string;
    } & OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>);

export interface GenericCardProps {
  size?: string;
  color?: string;
  image?: string;
  title?: string;
  content?: string;
  dateTime?: string;
  secondary?: string;
  iconPrimary?: OverrideIcon;
  primary?: string | number | undefined;
}

export interface IMenuItem {
  id?: string;
  url?: string;
  type?: string;
  title?: string;
  target?: boolean;
  disabled?: boolean;
  external?: boolean;
  action?: () => void;
  breadcrumbs?: boolean;
  children?: IMenuItem[];
  logoUrl?: null | string;
  icon?: string | GenericCardProps['iconPrimary'];
}

export interface IMenuInitialState {
  openItem?: string[];
  openComponent?: string;
  isSidebarOpen?: boolean;
  componentSidebarOpen?: boolean;
}

const initialState: IMenuInitialState = {
  isSidebarOpen: false,
  openComponent: 'buttons',
  componentSidebarOpen: true,
  openItem: ['bonus-management'],
};

const menuSlice = createSlice({
  initialState,
  name: 'menu',
  reducers: {
    activeItem(
      state: IMenuInitialState,
      action: PayloadAction<IMenuInitialState>
    ) {
      state.openItem = action.payload.openItem;
    },

    openSidebar(
      state: IMenuInitialState,
      action: PayloadAction<IMenuInitialState>
    ) {
      state.isSidebarOpen = action.payload.isSidebarOpen;
    },

    activeComponent(
      state: IMenuInitialState,
      action: PayloadAction<IMenuInitialState>
    ) {
      state.openComponent = action.payload.openComponent;
    },

    openComponentSidebar(
      state: IMenuInitialState,
      action: PayloadAction<IMenuInitialState>
    ) {
      state.componentSidebarOpen = action.payload.componentSidebarOpen;
    },
  },
});

export const {
  activeItem,
  openSidebar,
  activeComponent,
  openComponentSidebar,
} = menuSlice.actions;

export default menuSlice.reducer;
