import { type Theme } from '@mui/material/styles';

export default function TableCell(theme: Theme) {
  const commonCell = {
    '&:not(:last-of-type)': {
      position: 'relative',
      '&:after': {
        top: 16,
        right: 0,
        width: 1,
        content: '""',
        position: 'absolute',
        height: 'calc(100% - 30px)',
        backgroundColor: theme.palette.divider,
      },
    },
  };

  return {
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: 8,
        },
        footer: {
          fontSize: '0.75rem',
          textTransform: 'uppercase',
          ...commonCell,
        },
        root: {
          padding: 12,
          fontSize: '0.875rem',
          borderColor: theme.palette.divider,
        },
        head: {
          fontWeight: 700,
          fontSize: '0.75rem',
          textTransform: 'uppercase',
          ...commonCell,
        },
      },
    },
  };
}
