import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formattedDate = (
  date: null | Date | number,
  timeZone?: string
): string => {
  if (!date) {
    return '';
  }

  const dividedDate = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
    timeStyle: 'medium',
    ...(timeZone ? { timeZone } : { timeZone: 'GMT' }),
  }).formatToParts(date);

  const year = dividedDate.find((el) => el.type === 'year')?.value ?? '';
  const month = dividedDate.find((el) => el.type === 'month')?.value ?? '';
  const day = dividedDate.find((el) => el.type === 'day')?.value ?? '';
  const hour = dividedDate.find((el) => el.type === 'hour')?.value ?? '';
  const minute = dividedDate.find((el) => el.type === 'minute')?.value ?? '';
  const second = dividedDate.find((el) => el.type === 'second')?.value ?? '';

  const connectedDate = `${day}-${month}-${year}, ${hour}:${minute}:${second}`;

  return connectedDate;
};
