import { alpha, type Theme } from '@mui/material/styles';

import { getColors } from '@themes/utils/getColors';
import { getShadow } from '@themes/utils/getShadow';
import {
  type ButtonVariantProps,
  type ExtendedStyleProps,
} from '@themes/types/extended';

interface ButtonStyleProps extends ExtendedStyleProps {
  variant: ButtonVariantProps;
}

function getColorStyle({ theme, color, variant }: ButtonStyleProps) {
  const colors = getColors(theme, color);
  const { dark, main, lighter, contrastText } = colors;

  const buttonShadow = `${color ?? 'inherit'}Button`;
  const shadows = getShadow(theme, buttonShadow);

  const commonShadow = {
    '&::after': {
      boxShadow: `0 0 5px 5px ${alpha(main, 0.9)}`,
    },
    '&:active::after': {
      boxShadow: `0 0 0 0 ${alpha(main, 0.9)}`,
    },
    '&:focus-visible': {
      outlineOffset: 2,
      outline: `2px solid ${dark}`,
    },
  };

  switch (variant) {
    case 'contained':
      return {
        '&:hover': {
          backgroundColor: dark,
        },
        ...commonShadow,
      };
    case 'shadow':
      return {
        boxShadow: shadows,
        color: contrastText,
        backgroundColor: main,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: dark,
        },
        ...commonShadow,
      };
    case 'outlined':
      return {
        color: dark,
        borderColor: dark,
        '&:hover': {
          borderColor: dark,
          backgroundColor: 'transparent',
          boxShadow: `0 0 1px 1px ${alpha(main, 0.4)}`,
        },
        ...commonShadow,
      };
    case 'dashed':
      return {
        color: main,
        borderColor: main,
        backgroundColor: lighter,
        '&:hover': {
          color: dark,
          borderColor: dark,
        },
        ...commonShadow,
      };
    case 'text':
    default:
      return {
        '&:hover': {
          color: dark,
          backgroundColor: lighter,
        },
        ...commonShadow,
      };
  }
}

export default function Button(theme: Theme) {
  const primaryDashed = getColorStyle({
    theme,
    color: 'primary',
    variant: 'dashed',
  });
  const primaryShadow = getColorStyle({
    theme,
    color: 'primary',
    variant: 'shadow',
  });

  const disabledStyle = {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  };
  const iconStyle = {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit',
    },
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        endIcon: {
          ...iconStyle,
        },
        startIcon: {
          ...iconStyle,
        },
        outlined: {
          ...disabledStyle,
        },
        contained: {
          ...disabledStyle,
        },
        textInfo: getColorStyle({ theme, color: 'info', variant: 'text' }),
        textError: getColorStyle({ theme, color: 'error', variant: 'text' }),
        textWarning: getColorStyle({
          theme,
          variant: 'text',
          color: 'warning',
        }),
        textSuccess: getColorStyle({
          theme,
          variant: 'text',
          color: 'success',
        }),
        textPrimary: getColorStyle({
          theme,
          variant: 'text',
          color: 'primary',
        }),
        sizeExtraSmall: {
          minWidth: 56,
          padding: '2px 8px',
          fontSize: '0.625rem',
        },
        outlinedInfo: getColorStyle({
          theme,
          color: 'info',
          variant: 'outlined',
        }),
        text: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        textSecondary: getColorStyle({
          theme,
          variant: 'text',
          color: 'secondary',
        }),
        outlinedError: getColorStyle({
          theme,
          color: 'error',
          variant: 'outlined',
        }),
        containedInfo: getColorStyle({
          theme,
          color: 'info',
          variant: 'contained',
        }),
        containedError: getColorStyle({
          theme,
          color: 'error',
          variant: 'contained',
        }),
        outlinedWarning: getColorStyle({
          theme,
          color: 'warning',
          variant: 'outlined',
        }),
        outlinedSuccess: getColorStyle({
          theme,
          color: 'success',
          variant: 'outlined',
        }),
        outlinedPrimary: getColorStyle({
          theme,
          color: 'primary',
          variant: 'outlined',
        }),
        containedWarning: getColorStyle({
          theme,
          color: 'warning',
          variant: 'contained',
        }),
        containedSuccess: getColorStyle({
          theme,
          color: 'success',
          variant: 'contained',
        }),
        containedPrimary: getColorStyle({
          theme,
          color: 'primary',
          variant: 'contained',
        }),
        outlinedSecondary: getColorStyle({
          theme,
          color: 'secondary',
          variant: 'outlined',
        }),
        containedSecondary: getColorStyle({
          theme,
          color: 'secondary',
          variant: 'contained',
        }),
        root: {
          fontWeight: 400,
          '&:active::after': {
            top: 0,
            left: 0,
            opacity: 1,
            borderRadius: 4,
            transition: '0s',
            position: 'absolute',
          },

          '&::after': {
            top: 0,
            left: 0,
            opacity: 0,
            width: '100%',
            content: '""',
            height: '100%',
            borderRadius: 4,
            display: 'block',
            position: 'absolute',
            transition: 'all 0.5s',
          },
        },
        shadow: {
          ...primaryShadow,
          '&.MuiButton-shadowInfo': getColorStyle({
            theme,
            color: 'info',
            variant: 'shadow',
          }),
          '&.MuiButton-shadowError': getColorStyle({
            theme,
            color: 'error',
            variant: 'shadow',
          }),
          '&.MuiButton-shadowWarning': getColorStyle({
            theme,
            color: 'warning',
            variant: 'shadow',
          }),
          '&.MuiButton-shadowSuccess': getColorStyle({
            theme,
            color: 'success',
            variant: 'shadow',
          }),
          '&.MuiButton-shadowPrimary': getColorStyle({
            theme,
            color: 'primary',
            variant: 'shadow',
          }),
          '&.MuiButton-shadowSecondary': getColorStyle({
            theme,
            variant: 'shadow',
            color: 'secondary',
          }),
          '&.Mui-disabled': {
            color: `${theme.palette.grey[300]} !important`,
            borderColor: `${theme.palette.grey[400]} !important`,
            backgroundColor: `${theme.palette.grey[200]} !important`,
          },
        },
        dashed: {
          border: '1px dashed',
          ...primaryDashed,
          '&.MuiButton-dashedInfo': getColorStyle({
            theme,
            color: 'info',
            variant: 'dashed',
          }),
          '&.MuiButton-dashedError': getColorStyle({
            theme,
            color: 'error',
            variant: 'dashed',
          }),
          '&.MuiButton-dashedWarning': getColorStyle({
            theme,
            color: 'warning',
            variant: 'dashed',
          }),
          '&.MuiButton-dashedSuccess': getColorStyle({
            theme,
            color: 'success',
            variant: 'dashed',
          }),
          '&.MuiButton-dashedPrimary': getColorStyle({
            theme,
            color: 'primary',
            variant: 'dashed',
          }),
          '&.MuiButton-dashedSecondary': getColorStyle({
            theme,
            variant: 'dashed',
            color: 'secondary',
          }),
          '&.Mui-disabled': {
            color: `${theme.palette.grey[300]} !important`,
            borderColor: `${theme.palette.grey[400]} !important`,
            backgroundColor: `${theme.palette.grey[200]} !important`,
          },
        },
      },
    },
  };
}
