import { alpha, createTheme } from '@mui/material/styles';

import ThemeOption from '@themes/theme/index';
import { type PaletteThemeProps } from '@themes/types/theme';
import { type ThemeMode, type PresetColor } from '@features/appConfig/types';
import {
  presetPalettes,
  type PalettesProps,
  presetDarkPalettes,
} from '@themes/theme/presetPalettes';

const Palette = (mode: ThemeMode, presetColor: PresetColor) => {
  const colors: PalettesProps =
    mode === 'dark' ? presetDarkPalettes : presetPalettes;

  let greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#1c4d5c',
    '#141414',
    '#000000',
  ];
  let greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  let greyConstant = ['#fafafb', '#e6ebf1'];
  let orbitBackground = ['#eff2f4'];

  if (mode === 'dark') {
    greyPrimary = [
      '#000000',
      '#141414',
      '#1e1e1e',
      '#595959',
      '#8c8c8c',
      '#bfbfbf',
      '#d9d9d9',
      '#f0f0f0',
      '#f5f5f5',
      '#fafafa',
      '#ffffff',
    ];
    greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    greyConstant = ['#121212', '#d3d8db'];
    orbitBackground = ['#1e1e1e'];
  }
  colors.grey = [
    ...greyPrimary,
    ...greyAscent,
    ...greyConstant,
    ...orbitBackground,
  ];

  const paletteColor: PaletteThemeProps = ThemeOption(
    colors,
    presetColor,
    mode
  );

  return createTheme({
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff',
      },
      ...paletteColor,
      action: {
        disabled: paletteColor.grey[300],
      },
      divider:
        mode === 'dark'
          ? alpha(paletteColor.grey[900] ?? colors.grey[10], 0.05)
          : paletteColor.grey[200],
      background: {
        default: paletteColor.grey.A50,
        paper: mode === 'dark' ? paletteColor.grey[100] : paletteColor.grey[0],
      },
      text: {
        disabled:
          mode === 'dark'
            ? alpha(paletteColor.grey[900] ?? colors.grey[10], 0.1)
            : paletteColor.grey[400],
        primary:
          mode === 'dark'
            ? alpha(paletteColor.grey[900] ?? colors.grey[10], 0.87)
            : paletteColor.grey[700],
        secondary:
          mode === 'dark'
            ? alpha(paletteColor.grey[900] ?? colors.grey[10], 0.45)
            : paletteColor.grey[500],
      },
    },
  });
};

export default Palette;
