export default function Autocomplete() {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          overscrollBehavior: 'none',
        },
        tag: {
          margin: '0 0.188rem 0.125rem 0 ',
        },
        root: {
          '& .MuiChip-root': {
            maxHeight: '1.25rem',
            maxWidth: 'calc(100% - 4rem)',
          },
          '& .MuiOutlinedInput-root': {
            '& .MuiAutocomplete-endAdornment': {
              right: 0,
              '& button': {
                margin: 0,
                width: '2.125rem',
                height: '2.125rem',
              },
            },
          },
        },
      },
    },
  };
}
