import { createApi } from '@reduxjs/toolkit/query/react';

import { API, ENV } from '@constants';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import { type ISearchParams } from '@features/filters/types';
import { type IPlayerBonusesSearch } from '@modules/playerBonuses/types';

export const playerReportingServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'playerReportingService',
  baseQuery: (...args) =>
    baseQueryCreator(ENV.VITE_PLAYER_REPORTING_SERVICE_API_URL)(...args),
});
export const playerBonusesApi = playerReportingServiceSlice
  .enhanceEndpoints({
    addTagTypes: ['player-bonuses-list'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlayerBonusesList: builder.query<IPlayerBonusesSearch, ISearchParams>({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.PLAYER_BONUSES_LIST.GRID,
        }),
      }),
      exportPlayerBonusesData: builder.mutation<
        {
          response: string;
          headers: Record<string, string>;
        },
        ISearchParams
      >({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.PLAYER_BONUSES_LIST.EXPORT,
          responseHandler: async (response: Response) => {
            const text = await response.text();
            const headers: Record<string, string> = {};
            response.headers.forEach((value: string, key: string) => {
              headers[key] = value;
            });
            return { headers, response: text };
          },
        }),
      }),
    }),
  });

export const {
  useLazyGetPlayerBonusesListQuery,
  useExportPlayerBonusesDataMutation,
} = playerBonusesApi;
