import { type Theme } from '@mui/material/styles';

export default function InputBase(theme: Theme) {
  const disabledColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[400]
      : theme.palette.grey[200];
  const borderColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[500]
      : theme.palette.grey[400];
  return {
    MuiInputLabel: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.75rem',
        },
        root: {
          '&.Mui-disabled': {
            color: disabledColor,
            WebkitTextFillColor: disabledColor,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.75rem',
        },
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor,
          },
          input: {
            '&:disabled': {
              border: 'none',
              color: disabledColor,
              WebkitTextFillColor: disabledColor,
            },
          },
        },
      },
    },
  };
}
