import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type IUserInfo } from '@features/auth/types';

interface IAuth {
  token: string;
  userType: string;
  userInfo: IUserInfo;
  permissions: string[];
}

const initialState: IAuth = {
  token: '',
  userType: '',
  permissions: [],
  userInfo: {
    username: '',
  },
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    logOut: (state) => {
      state.token = '';
      state.userType = '';
      state.permissions = [];
    },
    setCredentials: (state: IAuth, action: PayloadAction<IAuth>) => {
      const {
        token = '',
        userType = '',
        permissions = [],
        userInfo = { username: '' },
      } = action.payload;
      state.token = token;
      state.userType = userType;
      state.permissions = permissions;
      state.userInfo = userInfo;
    },
  },
});

export const { logOut, setCredentials } = authSlice.actions;

export default authSlice.reducer;
