import { toast } from 'react-toastify';
import { type RootState } from '@store';
import { APP_VERSION } from '@constants/config';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
  type FetchArgs,
  fetchBaseQuery,
  type BaseQueryFn,
} from '@reduxjs/toolkit/query';

import { ENV } from '@constants';

const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set('X-FE-Version', APP_VERSION);

      const {
        auth: { token },
      } = getState() as RootState;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      if (
        ENV.VITE_BONUS_SERVICE_X_API_KEY &&
        baseUrl === ENV.VITE_BONUS_SERVICE_API_URL
      ) {
        headers.set('X-API-Key', ENV.VITE_BONUS_SERVICE_X_API_KEY);
      }

      return headers;
    },
  });

type IBaseQueryCreator = (
  path: string
) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export const baseQueryCreator: IBaseQueryCreator =
  (path: string) => async (args, api, extraOptions) => {
    const result = await baseQuery(path)(args, api, extraOptions);

    // Description: Handle all errors which does not cover by BE
    if (result?.error) {
      const errorData = result.error.data as Record<string, string>;

      if (!errorData?.traceId) {
        toast.error(
          `${String(result?.error?.status)}: ${
            errorData?.title ?? errorData?.error ?? ''
          }`,
          { bodyStyle: { whiteSpace: 'pre-wrap' } }
        );
      }
    }

    return result;
  };
