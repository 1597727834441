import { type Theme } from '@mui/material/styles';

export default function Dialog(theme: Theme) {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '2rem 2rem 1rem 2rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0.5rem 2rem 2rem 2rem',
        },
      },
    },
  };
}
