import { createApi } from '@reduxjs/toolkit/query/react';

import { ENV } from '@constants';
import { baseQueryCreator } from '@utils/baseQueryCreator';

export const apiSlice = createApi({
  reducerPath: 'api',
  endpoints: () => ({}),
  refetchOnReconnect: true,
  baseQuery: (...args) => baseQueryCreator(ENV.VITE_BONUS_API_URL)(...args),
});
