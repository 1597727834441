import { createApi } from '@reduxjs/toolkit/query/react';

import { API, ENV } from '@constants';
import { type Option } from '@shared/form/types';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import { responseToSelectOptions } from '@utils/responseFormatters';
import {
  type ICasinoGamesData,
  type ICasinoGamesPayload,
  type ICasinoGamesConditionRow,
} from '@modules/common/casinoGames/types';

export const casinoGamesServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'casinoGamesData',
  baseQuery: (...args) => baseQueryCreator(ENV.VITE_BONUS_API_URL)(...args),
});

export const casinoGamesApi = casinoGamesServiceSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCondition: builder.query<
      ICasinoGamesConditionRow[],
      Partial<ICasinoGamesPayload>
    >({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.CASINO_GAMES_DATA.CREATE,
      }),
    }),

    getSubProviders: builder.query<Option[], Partial<ICasinoGamesPayload>>({
      serializeQueryArgs: () => 'getSubProviders',
      transformResponse: (response: ICasinoGamesData[]) =>
        responseToSelectOptions<ICasinoGamesData[]>(response, { key: 'name' }),
      query: ({ brandIds }) => ({
        params: {
          brandIds,
        },
        url: API.CASINO_GAMES_DATA.SEARCH_SUB_PROVIDER,
      }),
    }),

    getGameTypes: builder.query<Option[], Partial<ICasinoGamesPayload>>({
      serializeQueryArgs: () => 'getGameTypes',
      transformResponse: (response: ICasinoGamesData[]) =>
        responseToSelectOptions<ICasinoGamesData[]>(response, { key: 'name' }),
      query: ({ brandIds, subProviderIds }) => ({
        url: API.CASINO_GAMES_DATA.SEARCH_GAME_TYPE,
        params: {
          brandIds,
          subProviderIds,
        },
      }),
    }),

    getGameNames: builder.query<Option[], Partial<ICasinoGamesPayload>>({
      serializeQueryArgs: () => 'getGameNames',
      transformResponse: (response: ICasinoGamesData[]) =>
        responseToSelectOptions<ICasinoGamesData[]>(response, { key: 'name' }),
      query: ({ brandIds, gameTypeIds, subProviderIds }) => ({
        url: API.CASINO_GAMES_DATA.SEARCH_GAME_NAME,
        params: {
          brandIds,
          gameTypeIds,
          subProviderIds,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetGameNamesQuery,
  useLazyGetGameTypesQuery,
  useLazyGetSubProvidersQuery,
  useLazyCreateConditionQuery,
} = casinoGamesApi;

const queries = ['getSubProviders', 'getGameTypes', 'getGameNames'] as const;

export type CasinoGameDataQueryKey = (typeof queries)[number];
