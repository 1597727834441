import { GrSchedules } from 'react-icons/gr';
import { BiTargetLock } from 'react-icons/bi';
import { FaBusinessTime } from 'react-icons/fa';
import { PiFrameCornersBold } from 'react-icons/pi';
import { FiActivity, FiSettings } from 'react-icons/fi';
import { TbAward, TbReportAnalytics } from 'react-icons/tb';
import { BsFillEyeFill, BsCurrencyExchange } from 'react-icons/bs';

import { ROUTES } from '@constants';
import { type IMenuItem } from '@features/menu/menuSlice';

const menuIconsConfigMap = {
  playerBonuses: TbAward,
  appSettings: FiSettings,
  iFrameMode: PiFrameCornersBold,
  scheduledCampaigns: GrSchedules,
  playerOffers: TbReportAnalytics,
  giveBonusManagement: BiTargetLock,
  campaignActivityReport: FiActivity,
  automaticCampaigns: FaBusinessTime,
  bonusManagement: BsCurrencyExchange,
  campaignMonitorReport: BsFillEyeFill,
};

export const menuConfig: IMenuItem[] = [
  {
    id: 'automatic-campaigns',
    type: 'item',
    breadcrumbs: true,
    title: 'Automatic Campaigns',
    url: ROUTES.AUTOMATIC_CAMPAIGNS.ROOT,
    icon: menuIconsConfigMap.automaticCampaigns,
  },
  {
    id: 'scheduled-campaigns',
    type: 'item',
    breadcrumbs: true,
    title: 'Scheduled Campaigns',
    url: ROUTES.SCHEDULED_CAMPAIGNS.ROOT,
    icon: menuIconsConfigMap.scheduledCampaigns,
  },
  {
    id: 'bonus-management',
    type: 'item',
    breadcrumbs: true,
    title: 'Bonus Management',
    url: ROUTES.BONUS_MANAGEMENT.ROOT,
    icon: menuIconsConfigMap.bonusManagement,
  },
  {
    id: 'give-bonus',
    type: 'item',
    breadcrumbs: true,
    title: 'Give Bonus',
    url: ROUTES.GIVE_BONUS_MANAGEMENT.ROOT,
    icon: menuIconsConfigMap.giveBonusManagement,
  },
  {
    id: 'player-offers',
    type: 'item',
    breadcrumbs: true,
    title: 'Player Offers Report',
    url: ROUTES.PLAYER_OFFERS.ROOT,
    icon: menuIconsConfigMap.playerOffers,
  },
  {
    id: 'player-bonuses',
    type: 'item',
    breadcrumbs: true,
    title: 'Player Bonuses Report',
    url: ROUTES.PLAYER_BONUSES.ROOT,
    icon: menuIconsConfigMap.playerBonuses,
  },
  {
    id: 'monitor-report',
    type: 'item',
    breadcrumbs: true,
    title: 'Campaign Monitor Report',
    url: ROUTES.CAMPAIGN_MONITOR_REPORT.ROOT,
    icon: menuIconsConfigMap.campaignMonitorReport,
  },
  {
    id: 'activity-report',
    type: 'item',
    breadcrumbs: true,
    title: 'Campaign Activity Report',
    url: ROUTES.CAMPAIGN_ACTIVITY_REPORT.ROOT,
    icon: menuIconsConfigMap.campaignActivityReport,
  },
  {
    id: 'app-settings',
    type: 'item',
    breadcrumbs: true,
    title: 'App Settings',
    url: ROUTES.APP_SETTINGS.ROOT,
    icon: menuIconsConfigMap.appSettings,
  },
];

export const menuItems = {
  items: menuConfig,
};
