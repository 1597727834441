import type { Theme } from '@mui/material/styles';

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&.Mui-disabled': {
            pointerEvents: 'auto',
          },
        },
        sizeMedium: {
          fontSize: '1rem',
          width: theme.spacing(4.5),
          height: theme.spacing(4.5),
        },
        sizeLarge: {
          fontSize: '1.25rem',
          width: theme.spacing(5.5),
          height: theme.spacing(5.5),
        },
        sizeSmall: {
          fontSize: '0.75rem',
          width: theme.spacing(3.75),
          height: theme.spacing(3.75),
        },
      },
    },
  };
}
