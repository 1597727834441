import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type IAppConfig } from '@features/appConfig/types';

export const initialState: IAppConfig = {
  config: {
    mode: 'light',
    container: false,
    themeDirection: 'ltr',
    presetColor: 'Orange',
    fontFamily: "'Montserrat', sans-serif",
  },
};

const appConfigSlice = createSlice({
  initialState,
  name: 'appConfig',
  reducers: {
    setAppConfig(
      state: IAppConfig,
      action: PayloadAction<Partial<IAppConfig['config']>>
    ) {
      state.config = { ...state.config, ...action.payload };
    },
  },
});

export const { setAppConfig } = appConfigSlice.actions;

export default appConfigSlice.reducer;
