import {
  type Theme,
  type TypographyVariantsOptions,
} from '@mui/material/styles';

import { type ThemeMode, type FontFamily } from '@features/appConfig/types';

const Typography = (
  mode: ThemeMode,
  fontFamily: FontFamily,
  theme: Theme
): TypographyVariantsOptions => ({
  fontFamily,
  htmlFontSize: 16,
  fontWeightBold: 600,
  fontWeightLight: 300,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  overline: {
    lineHeight: 1.66,
  },
  button: {
    textTransform: 'capitalize',
  },
  body2: {
    lineHeight: 1.66,
    fontSize: '0.75rem',
  },
  body1: {
    lineHeight: 1.57,
    fontSize: '0.875rem',
  },
  h5: {
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: '1rem',
  },
  h4: {
    lineHeight: 1.4,
    fontWeight: 600,
    fontSize: '1.25rem',
  },
  h3: {
    fontWeight: 600,
    lineHeight: 1.33,
    fontSize: '1.5rem',
  },
  h6: {
    fontWeight: 400,
    lineHeight: 1.57,
    fontSize: '0.875rem',
  },
  h2: {
    fontWeight: 600,
    lineHeight: 1.27,
    fontSize: '1.875rem',
  },
  h1: {
    fontWeight: 600,
    lineHeight: 1.21,
    fontSize: '2.375rem',
  },
  caption: {
    fontWeight: 400,
    lineHeight: 1.66,
    fontSize: '0.75rem',
  },
  subtitle2: {
    fontWeight: 500,
    lineHeight: 1.66,
    fontSize: '0.75rem',
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.57,
    fontSize: '0.875rem',
  },
});

export default Typography;
