import { type Theme } from '@mui/material/styles';

export default function Tooltip(theme: Theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          lineHeight: '1rem',
          color: theme.palette.background.paper,
        },
      },
    },
  };
}
