export default function TablePagination() {
  return {
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          display: 'none',
        },
        selectLabel: {
          fontSize: '0.875rem',
        },
        displayedRows: {
          fontSize: '0.875rem',
        },
      },
    },
  };
}
