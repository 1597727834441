import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type Option } from '@shared/form/types';
import { initialFilters } from '@modules/bonusManagement/constants';
import {
  OPERATOR_SORT,
  OPERATOR_TYPE,
  type ISearchParams,
  type IInitialFilter,
} from '@features/filters/types';

export const initialBonusSearchParams = {
  page: 1,
  size: 10,
  filters: [{ value: true, field: 'enabled', type: OPERATOR_TYPE.EQUAL }],
  sorts: [
    {
      field: 'modified_at',
      order: OPERATOR_SORT.DESC,
    },
  ],
};

interface InitialState {
  searchParams: ISearchParams;
  prevFilters: Record<string, IInitialFilter>;
  dialogState: {
    isConfirmBrandsDialogOpen: boolean;
  };
  activeBonus: {
    enabled: boolean;
    id: null | string;
    name: null | string;
  };
  bonusConfig: {
    type: string;
    activeStep: number;
    isCreateAction: boolean;
    currentBrands: Option[];
    previousBrands: Option[];
    brandChangeReason: string;
    hasBrandsWithoutAccess: boolean;
  };
}

const initialState: InitialState = {
  prevFilters: initialFilters,
  searchParams: initialBonusSearchParams,
  dialogState: {
    isConfirmBrandsDialogOpen: false,
  },
  activeBonus: {
    id: null,
    name: null,
    enabled: false,
  },
  bonusConfig: {
    type: '',
    activeStep: 0,
    currentBrands: [],
    previousBrands: [],
    brandChangeReason: '',
    isCreateAction: false,
    hasBrandsWithoutAccess: false,
  },
};

const bonusesSlice = createSlice({
  initialState,
  name: 'bonusesSlice',
  reducers: {
    setSearchParams(state: InitialState, action: PayloadAction<ISearchParams>) {
      state.searchParams = action.payload;
    },
    setActiveStep(state: InitialState, action: PayloadAction<number>) {
      state.bonusConfig.activeStep = action.payload;
    },
    setActiveBonus(
      state: InitialState,
      action: PayloadAction<InitialState['activeBonus']>
    ) {
      state.activeBonus = action.payload;
    },
    setPrevFilters(
      state: InitialState,
      action: PayloadAction<Record<string, IInitialFilter>>
    ) {
      state.prevFilters = action.payload;
    },
    setBonusConfig(
      state: InitialState,
      action: PayloadAction<Partial<InitialState['bonusConfig']>>
    ) {
      state.bonusConfig = { ...state.bonusConfig, ...action.payload };
    },
    setBonusDialogState(
      state: InitialState,
      action: PayloadAction<Partial<InitialState['dialogState']>>
    ) {
      state.dialogState = { ...state.dialogState, ...action.payload };
    },
  },
});

export default bonusesSlice.reducer;

export const {
  setActiveStep,
  setPrevFilters,
  setBonusConfig,
  setActiveBonus,
  setSearchParams,
  setBonusDialogState,
} = bonusesSlice.actions;
