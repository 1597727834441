import { useMemo } from 'react';
import { type SerializedError } from '@reduxjs/toolkit';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { type ICommonError } from '@modules/types';
import { type IValidationWizardStep } from '@modules/bonusManagement/types';
import {
  useValidateWizardStepMutation,
  useValidateEditWizardStepMutation,
} from '@modules/bonusManagement/submodules/bonus/service/bonusDetailsApi';

type IAction = (
  data: IValidationWizardStep
) => Promise<ICommonError | { error: SerializedError | FetchBaseQueryError }>;

export const useValidationStep = (
  isEditMode: boolean
): { validateWizardStep: IAction; isLoadingValidateWizardStep: boolean } => {
  const [
    validateCreateWizardStep,
    { isLoading: isLoadingValidateCreateWizardStep },
  ] = useValidateWizardStepMutation({
    fixedCacheKey: 'partialValidationCreate',
  });

  const [
    validateEditWizardStep,
    { isLoading: isLoadingValidateEditWizardStep },
  ] = useValidateEditWizardStepMutation({
    fixedCacheKey: 'partialValidationEdit',
  });

  const validateWizardStep = useMemo(
    () =>
      isEditMode
        ? (payload: IValidationWizardStep) =>
            validateEditWizardStep(payload).unwrap()
        : (payload: IValidationWizardStep) =>
            validateCreateWizardStep(payload).unwrap(),
    [isEditMode, validateCreateWizardStep, validateEditWizardStep]
  );

  const isLoadingValidateWizardStep = useMemo(
    () =>
      isEditMode
        ? isLoadingValidateEditWizardStep
        : isLoadingValidateCreateWizardStep,
    [
      isEditMode,
      isLoadingValidateCreateWizardStep,
      isLoadingValidateEditWizardStep,
    ]
  );

  return {
    validateWizardStep,
    isLoadingValidateWizardStep,
  };
};
