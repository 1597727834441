import { createApi } from '@reduxjs/toolkit/query/react';

import { API, ENV } from '@constants';
import replaceURLParams from '@utils/replaceURLParams';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import { type ISearchParams } from '@features/filters/types';
import {
  type IDecision,
  type ICampaignMonitorSearch,
} from '@modules/campaignMonitorReport/types';

export const campaignMonitorReportingServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'сampaignMonitorReportingService',
  baseQuery: (...args) =>
    baseQueryCreator(ENV.VITE_PLAYER_REPORTING_SERVICE_API_URL)(...args),
});
export const campaignMonitorApi = campaignMonitorReportingServiceSlice
  .enhanceEndpoints({
    addTagTypes: ['campaign-monitor-list', 'campaign-decision'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCampaignMonitorList: builder.query<
        ICampaignMonitorSearch,
        ISearchParams
      >({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.CAMPAIGN_MANAGEMENT.MONITOR_REPORT.ROOT,
        }),
      }),

      getCampaignDecision: builder.query<IDecision, string>({
        query: (id) => ({
          headers: {
            'content-type': 'application/json',
          },
          url: replaceURLParams(
            API.CAMPAIGN_MANAGEMENT.MONITOR_REPORT.CAMPAIGN_DECISION,
            {
              id,
            }
          ),
        }),
      }),

      getBonusTemplateDecision: builder.query<IDecision, string>({
        query: (id) => ({
          headers: {
            'content-type': 'application/json',
          },
          url: replaceURLParams(
            API.CAMPAIGN_MANAGEMENT.MONITOR_REPORT.BONUS_TEMPLATE_DECISION,
            {
              id,
            }
          ),
        }),
      }),
    }),
  });

export const {
  useGetCampaignDecisionQuery,
  useGetBonusTemplateDecisionQuery,
  useLazyGetCampaignMonitorListQuery,
} = campaignMonitorApi;
