import { type Theme } from '@mui/material/styles';

export default function DataGrid(theme: Theme) {
  const borderColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[500]
      : theme.palette.grey[200];
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderColor,
          fontSize: '0.85rem',
          '.MuiDataGrid-withBorderColor': {
            borderColor,
          },
          '.MuiDataGrid-columnHeader': {
            fontSize: '1.1rem',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
        },
      },
    },
  };
}
