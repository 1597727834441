import { API } from '@constants';
import { apiSlice } from '@api/apiSlice';
import { type ICatalog } from '@api/types';
import { type Option } from '@shared/form/types';
import { catchCommonError } from '@utils/catchCommonError';
import { responseToSelectOptions } from '@utils/responseFormatters';

export const systemValuesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEnums: builder.query<ICatalog, unknown>({
      serializeQueryArgs: () => 'getEnums',
      query: () => ({
        url: API.CATALOG.ENUMS,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled.catch(({ error }) => {
          catchCommonError(error);
        });
      },
    }),
    getTimeZones: builder.query<Option[], unknown>({
      serializeQueryArgs: () => 'getTimeZones',
      query: () => ({
        url: API.CATALOG.TIME_ZONES,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled.catch(({ error }) => {
          catchCommonError(error);
        });
      },
    }),
    getCountries: builder.query<Option[], unknown>({
      serializeQueryArgs: () => 'getCountries',
      query: () => ({
        url: API.CATALOG.COUNTRIES,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled.catch(({ error }) => {
          catchCommonError(error);
        });
      },
      transformResponse: (response: Array<{ id: string; name: string }>) =>
        responseToSelectOptions<Array<{ id: string; name: string }>>(response, {
          key: 'name',
        }),
    }),
    getCurrencies: builder.query<Option[], unknown>({
      serializeQueryArgs: () => 'getCurrencies',
      query: () => ({
        url: API.CATALOG.CURRENCIES,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled.catch(({ error }) => {
          catchCommonError(error);
        });
      },
      transformResponse: (response: Array<{ id: string; code: string }>) =>
        responseToSelectOptions<Array<{ id: string; code: string }>>(response, {
          key: 'code',
        }),
    }),
    getLocales: builder.query<Option[], unknown>({
      serializeQueryArgs: () => 'getLocales',
      query: () => ({
        url: API.CATALOG.LOCALES,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled.catch(({ error }) => {
          catchCommonError(error);
        });
      },
      transformResponse: (
        response: Array<{ id: string; displayName: string }>
      ) =>
        responseToSelectOptions<Array<{ id: string; displayName: string }>>(
          response,
          { key: 'displayName' }
        ),
    }),
  }),
});

export const {
  useGetEnumsQuery,
  useGetLocalesQuery,
  useGetTimeZonesQuery,
  useGetCountriesQuery,
  useGetCurrenciesQuery,
} = systemValuesApiSlice;
