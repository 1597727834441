import { combineReducers } from 'redux';

import { apiSlice } from '@api/apiSlice';
import authReducer from '@features/auth/authSlice';
import menuReducer from '@features/menu/menuSlice';
import appConfigSlice from '@features/appConfig/appConfigSlice';
import bonusesSlice from '@modules/bonusManagement/store/bonusesSlice';
import playerOffersSlice from '@modules/playerOffers/store/playerOffersSlice';
import { ruleEngineApiSlice } from '@modules/ruleEngine/service/ruleEngineApi';
import sportsDataSlice from '@modules/common/sportsData/store/sportsDataSlice';
import { playerOffersApi } from '@modules/playerOffers/service/playerOffersApi';
import playerBonusesSlice from '@modules/playerBonuses/store/playerBonusesSlice';
import casinoGamesSlice from '@modules/common/casinoGames/store/casinoGamesSlice';
import { bonusServiceSlice } from '@modules/giveBonus/service/giveBonusManagementApi';
import campaignsSlice from '@modules/automaticCampaigns/store/automaticCampaignsSlice';
import { sportsDataServiceSlice } from '@modules/common/sportsData/service/sportsDataApi';
import { casinoGamesServiceSlice } from '@modules/common/casinoGames/service/casinoGamesApi';
import campaignMonitorSlice from '@modules/campaignMonitorReport/store/campaignMonitorSlice';
import { playerReportingServiceSlice } from '@modules/playerBonuses/service/playerBonusesApi';
import campaignActivitySlice from '@modules/campaignActivityReport/store/campaignActivitySlice';
import scheduledCampaignsSlice from '@modules/scheduledCampaigns/store/scheduledCampaignsSlice';
import { campaignMonitorReportingServiceSlice } from '@modules/campaignMonitorReport/service/campaignMonitorApi';
import { campaignActivityReportingServiceSlice } from '@modules/campaignActivityReport/service/campaignActivityApi';

const rootReducer = combineReducers({
  auth: authReducer,
  menu: menuReducer,
  appConfig: appConfigSlice,
  bonusesModule: bonusesSlice,
  sportsData: sportsDataSlice,
  casinoGames: casinoGamesSlice,
  playerOffers: playerOffersSlice,
  playerBonuses: playerBonusesSlice,
  automaticCampaigns: campaignsSlice,
  campaignMonitor: campaignMonitorSlice,
  campaignActivity: campaignActivitySlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
  scheduledCampaigns: scheduledCampaignsSlice,
  [playerOffersApi.reducerPath]: playerOffersApi.reducer,
  [bonusServiceSlice.reducerPath]: bonusServiceSlice.reducer,
  [ruleEngineApiSlice.reducerPath]: ruleEngineApiSlice.reducer,
  [sportsDataServiceSlice.reducerPath]: sportsDataServiceSlice.reducer,
  [casinoGamesServiceSlice.reducerPath]: casinoGamesServiceSlice.reducer,
  [playerReportingServiceSlice.reducerPath]:
    playerReportingServiceSlice.reducer,
  [campaignMonitorReportingServiceSlice.reducerPath]:
    campaignMonitorReportingServiceSlice.reducer,
  [campaignActivityReportingServiceSlice.reducerPath]:
    campaignActivityReportingServiceSlice.reducer,
});

export default rootReducer;
