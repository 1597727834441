type Params = Record<string, string>;

export function parseSearchParams(search: string, id: string): string {
  const searchParams = new URLSearchParams(search);
  const params: Params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params[id];
}
