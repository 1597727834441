import { lazy } from 'react';
import { Suspendable } from '@components';
import { type RouteObject } from 'react-router-dom';

import { ROUTES } from '@constants';

const CampaignActivityReport = Suspendable(
  lazy(() =>
    import('@modules/campaignActivityReport/controller').then((module) => ({
      default: module.CampaignActivityReportController,
    }))
  )
);

const Details = Suspendable(
  lazy(() =>
    import('@modules/playerBonuses/controller/index').then((module) => ({
      default: module.PlayerBonusesController,
    }))
  )
);

export const CampaignActivityRoutes: RouteObject[] = [
  {
    element: <CampaignActivityReport />,
    path: ROUTES.CAMPAIGN_ACTIVITY_REPORT.ROOT,
  },
  {
    element: <Details />,
    path: ROUTES.CAMPAIGN_ACTIVITY_REPORT.DETAILS,
  },
];
