import { type ICustomField } from '@ui/rule-engine';
import { createApi } from '@reduxjs/toolkit/query/react';

import { API, ENV } from '@constants';
import replaceURLParams from '@utils/replaceURLParams';
import { baseQueryCreator } from '@utils/baseQueryCreator';

export const ruleEngineApiSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'ruleEngineApi',
  baseQuery: (...args) => baseQueryCreator(ENV.VITE_BONUS_API_URL)(...args),
});

export const ruleEngineApi = ruleEngineApiSlice
  .enhanceEndpoints({
    addTagTypes: ['rules'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFields: builder.query<ICustomField[], string>({
        query: (key) => ({
          url: replaceURLParams(API.CATALOG.RULE_ENGINE.FIELDS, { key }),
        }),
      }),
    }),
  });

export const { useGetFieldsQuery } = ruleEngineApi;
