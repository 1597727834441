import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type ActiveFilters } from '@modules/campaignActivityReport/types';
import { getCurrentDateStartEndTime } from '@utils/getCurrentDateStartEndTime';
import {
  OPERATOR_SORT,
  OPERATOR_TYPE,
  type ISearchParams,
} from '@features/filters/types';

interface InitialState {
  searchParams: ISearchParams;
  activeFilters: ActiveFilters;
  expandedRows: Record<string, boolean>;
  prevPaginationParams: null | Partial<ISearchParams>;
}

export const initialCampaignActivitySearchParams = {
  page: 1,
  size: 10,
  sorts: [
    {
      field: 'campaign_name',
      order: OPERATOR_SORT.DESC,
    },
  ],
  filters: [
    {
      value: true,
      inversion: false,
      field: 'enabled',
      type: OPERATOR_TYPE.EQUAL,
    },
    {
      inversion: false,
      field: 'given_at',
      type: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
      value: getCurrentDateStartEndTime('start').valueOf(),
    },
    {
      inversion: false,
      field: 'given_at',
      type: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
      value: getCurrentDateStartEndTime('end').valueOf(),
    },
  ],
};

const initialState: InitialState = {
  expandedRows: {},
  activeFilters: {},
  prevPaginationParams: null,
  searchParams: initialCampaignActivitySearchParams,
};

const campaignActivitySlice = createSlice({
  initialState,
  name: 'campaignActivitySlice',
  reducers: {
    resetExpandedRows(state: InitialState) {
      state.expandedRows = initialState.expandedRows;
    },
    resetActiveFilters(state: InitialState) {
      state.activeFilters = initialState.activeFilters;
    },
    resetPrevPaginationParams(state: InitialState) {
      state.prevPaginationParams = initialState.prevPaginationParams;
    },
    setSearchParams(state: InitialState, action: PayloadAction<ISearchParams>) {
      state.searchParams = action.payload;
    },
    setActiveFilters(
      state: InitialState,
      action: PayloadAction<ActiveFilters>
    ) {
      state.activeFilters = action.payload;
    },
    setPrevPaginationParams(
      state: InitialState,
      action: PayloadAction<Partial<ISearchParams>>
    ) {
      state.prevPaginationParams = action.payload;
    },
    addExpandedRow(
      state: InitialState,
      action: PayloadAction<Record<string, boolean>>
    ) {
      state.expandedRows = { ...state.expandedRows, ...action.payload };
    },
    updateExpandedRow(
      state: InitialState,
      action: PayloadAction<Record<string, boolean>>
    ) {
      state.expandedRows = {
        ...state.expandedRows,
        [Object.keys(action.payload)[0]]: Object.values(action.payload)[0],
      };
    },
  },
});

export default campaignActivitySlice.reducer;

export const {
  addExpandedRow,
  setSearchParams,
  setActiveFilters,
  updateExpandedRow,
  resetExpandedRows,
  resetActiveFilters,
  setPrevPaginationParams,
  resetPrevPaginationParams,
} = campaignActivitySlice.actions;
