import get from 'lodash/get';
import { type EventHint } from '@sentry/react';
import { type ErrorEvent } from '@sentry/browser';

const rejectErrorMessages: Array<string | RegExp> = [
  'Failed to fetch dynamically imported module',
  'Element type is invalid. Received a promise that resolves to: undefined.',
  'React ErrorBoundary Error: Element type is invalid. Received a promise that resolves to: undefined. Lazy element type must resolve to a class or function.',
  'Error: Element type is invalid. Received a promise that resolves to: undefined.',
  'Element type is invalid. Received a promise that resolves to: undefined.',
  'Lazy element type must resolve to a class or function.',
  /Lazy/i,
  /Suspense/i,
  /dynamically imported/i,
];

export const handleRejectSentryErrors = (
  event: ErrorEvent,
  hint: EventHint
) => {
  const targetOriginalException = get(hint, 'originalException.message', '');
  const targetContextException = get(
    hint,
    'captureContext.contexts.react.componentStack',
    ''
  ) as string;

  if (
    rejectErrorMessages.some(
      (errorMessage) =>
        targetOriginalException.match(errorMessage) ??
        targetContextException.match(errorMessage)
    )
  ) {
    return null;
  }

  return event;
};
