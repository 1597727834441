import { type Theme } from '@mui/material/styles';

export default function ToggleButton(theme: Theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          fontSize: '0.750em',
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.contrastThreshold,
          border: `1px solid ${theme.palette.primary.main}`,
          '&:focus-visible': {
            outlineOffset: 2,
            outline: `2px solid ${theme.palette.secondary.dark}`,
          },
          '&[slot="Error"]': {
            color: theme.palette.error.main,
            border: `1px solid ${theme.palette.error.main}`,
          },
          ...{
            '&.Mui-disabled': {
              color: theme.palette.text.disabled,
              borderColor: theme.palette.grey[300],
            },
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            borderRight: `1px solid ${theme.palette.primary.light}`,
          },
        },
      },
    },
  };
}
